<template>
    <v-dialog
        v-model="show"
        max-width="500px"
        v-on:click:outside="cancelMethod"
    >
        <v-card>
            <v-toolbar
                color="primary"
                dark
                class="text-h5"
            >{{formTitle}}
                <v-spacer></v-spacer>
                <v-btn 
                    icon
                    @click="cancelMethod"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
        </v-toolbar>

            <v-card-text>
            <v-form ref="form">
                <v-container class="mt-4">
                <v-row>
                    <v-col>
                    <v-text-field
                    
                        v-model="updatedCompany.companyName"
                        label="Nome Azienda"
                        :rules="[rules.required, rules.counter]"
                        required
                    ></v-text-field>
                    </v-col>
                </v-row>
                
                </v-container>
            </v-form>
            </v-card-text>

            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                v-if="!edit"
                color="green accent-2"
                class="px-4"
                @click="createMethod"
            >
                <v-icon left>
                    mdi-check
                </v-icon>
                Salva
            </v-btn>
            <v-btn
                v-else
                color="amber lighten-1"
                class="px-4"
                @click="updateMethod"
            >
                <v-icon left>
                    mdi-check
                </v-icon>
                Modifica
            </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>


export default {
    data: function() {
        return {
            updatedCompany: {
                companyName: null
            },
            rules: {
                required: value => !!value || 'Required.',
                counter: value => (value || '').replace(/\s+/g, '').length >= 0 || 'Can\'t be empty',
            },
        }
    },
    props: {
        editedCompany: {
            type: Object,
            default: function() {
                return {}
            }
        },
        show: {
            type: Boolean,
            required: true
        },
        edit: {
            type: Boolean,
            default: false
        },
    },
    methods: {
        cancelMethod: function() {
            this.$refs.form.reset();
            this.$emit('dialog-close');
        },
        createMethod: function() {
            if (this.$refs.form.validate())
                this.$emit('create-company', this.updatedCompany);
        },
        updateMethod: function() {
            if (this.$refs.form.validate())
                this.$emit('update-company', this.editedCompany.companyName, this.updatedCompany);
        }
    },
    watch: {
        editedCompany: function(val) {
            let v = val || {};
            this.updatedCompany.companyName = v.companyName;
        },
    },
    computed: {
        formTitle: function() {
            return this.edit ? 'Modifica Azienda' : 'Crea Azienda'
        }
    }
}
</script>
